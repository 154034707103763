export const backgrounds = [
  { id: "1b", name: "sheepme" },
  { id: "2b", name: "queenme" },
  { id: "3b", name: "obamame" },
  { id: "4b", name: "pikame" },
  { id: "5b", name: "pumpkinme" },
  { id: "6b", name: "warholme" }
];

export const panelBackgrounds = [
  "../images/desk.jpg",
  "../images/waterlandscape.jpg",
  "../images/domestic_interior_1.jpg",
  "../images/domestic_interior_2.jpg"
];
